<template>
  <div>
    <v-sheet >
      <v-sheet  class=" justify-space-between align-center mb-4" :class="displayShow('sm') ? '' : 'd-flex' ">
        <div class="d-flex align-center"  >
          <v-btn large icon @click="$emit('close')">
            <v-icon large>mdi-chevron-left</v-icon></v-btn>
          <div>
            <h2>Склад: {{ warehouse.name }}</h2>
            <h5>{{ warehouse.address.city ? warehouse.address.city.text : '' }}</h5>
          </div>
        </div>
        <div :class="displayShow('sm') ? 'd-flex justify-center' : ''">
          <v-btn-toggle :class="displayShow('sm') ? 'mt-4' : '' " mandatory v-model="tab"   color="grey darken-2">
          <v-btn color="grey darken-2"  text class="noCaps font-weight-light">Управління</v-btn>
          <v-btn color="grey darken-2"  text class="noCaps font-weight-light">Облік операцій</v-btn>
          <v-btn v-if="!showBusinessToManager" color="grey darken-2"  text class="noCaps font-weight-light"><v-icon class="mr-1" small color="grey darken-2"  >mdi-cog</v-icon>Налаштування</v-btn>
<!--          <v-btn v-if="!showBusinessToManager" color="grey darken-2"  text class="noCaps font-weight-light"><v-icon class="mr-1" small color="grey darken-2"  >mdi-google-maps</v-icon>Зона дії</v-btn>-->

        </v-btn-toggle>
        </div>
      </v-sheet>

        <v-sheet v-if="tab === 0" elevation="1"  rounded outlined class="py-4 px-8 mt-8"  >
        <div class="d-flex justify-space-between align-center px-3 pb-5">
          <h3>Наявні товари</h3>



          <div v-if=" $store.state.dashboard.showBusinessToOwner ||
                      modulePermission('warehouse','warehouseManager') ">

            <v-btn  color="green lighten-2" dark rounded class="" :disabled="loading"  @click="openFillWare">
              <v-icon>mdi-plus</v-icon>
              Поповнити
            </v-btn>
          </div>

        </div>
        <v-divider class="mb-3" />
        <v-simple-table dense>
          <thead>
          <tr>
            <th>Товар</th>
            <th>Кількість</th>
            <th>Останнє поповнення</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(product,i) in warehouse.items" :key="i">

            <td class="py-2" width="300">
              <v-avatar class="mr-2 " size="32"  >
                <v-img :src="product.img" ratio="1" />
              </v-avatar>
              {{ product.name }}
            </td>
            <td width="120">{{ product.quantity }} шт</td>
            <td>
              <div v-if="product.lastUpdate">
                <v-chip small dark :color="product.lastUpdate.type === 'add' ? 'green lighten-2' : 'red lighten-3'">
                 <v-icon small class="mr-1">mdi-{{ product.lastUpdate.type === 'add' ? 'plus' : 'minus'  }}</v-icon>
                  <b>{{product.lastUpdate.data  }}</b>
                </v-chip>
              </div>

              </td>
          </tr>
          </tbody>
        </v-simple-table>
      </v-sheet>

        <div v-if="tab === 1">
          <v-divider />
          <warehouseRecords :records="records" />
        </div>

        <div v-if="tab === 2">
          <v-divider />
          <warehouseManagers :id="warehouse.id"
                             :managersAll="managersAll"
                             :users="warehouse.users"  />
        </div>

        <div v-if="tab === 3">
          <v-divider />
          <warehouseLocations :warehouse="warehouse" />
        </div>



    </v-sheet>

    <v-dialog width="900" v-model="showFulfillDialog">
      <fulfilWarehouse :managers="warehouse.users" v-if="showFulfillDialog"
                       :input="warehouse.items"
                       :warehouses="warehouses"
                       :uuid="uuid"
                       :type="warehouse.type"
                       :name="warehouse.name"
                       @close="showFulfillDialog=false"
                       @update="updateFulfillData"
      />
    </v-dialog>

  </div>
</template>

<script>
import {mapActions, mapState} from "vuex";
import fulfilWarehouse from "@/components/pages/businessDashboard/businessWarehouse/fulfilWarehouse";
import warehouseLocations from "@/components/pages/businessDashboard/businessWarehouse/warehouseLocations";
import warehouseManagers  from "@/components/pages/businessDashboard/businessWarehouse/warehouseManagers";
import warehouseRecords from "@/components/pages/businessDashboard/businessWarehouse/warehouseRecords";

export default {
  name: "manageWarehouse",
  props : ['uuid','warehouses'],
  components : {
    fulfilWarehouse,
    warehouseLocations,
    warehouseManagers,
    warehouseRecords
  },
  data() {
    return {
      tab : 0,
      loading : false,
      showFulfillDialog : false,
      filter : {
        year : '',
        month : '',
        day : '',
      },
      yearsDistinct : [],
      records : [],
      warehouse : {
        name : '',
        items : [],
        records : [],
        address : {
          city : { text : ''}
        }
      },
      managersAll : [],
      newManager : '',
    }
  },
  computed: {
    ...mapState('dashboard', ['showBusinessToManager']),

    recordsFiltered(){
      let records = this.records

      if ( this.filter.year ) {
        records = records.filter(el=> el.date.y === this.filter.year )
        if ( this.filter.month ) {
          records = records.filter(el=> el.date.m === this.filter.month )
          if ( this.filter.day ) {
            records = records.filter(el=> el.date.d === this.filter.day )
          }
        }
      }

      return records
    },
  },
  methods : {
    ...mapActions('warehouse', ['GET_WAREHOUSE','ADD_WAREHOUSE_MANAGER','DELETE_WAREHOUSE_MANAGER']),
    updateFulfillData(data) {




      this.warehouse.items = data.items
      this.records.unshift(data.record)

      this.showFulfillDialog = false
    },
    openFillWare() {
      this.showFulfillDialog = true
    },

    // technical
    getActionName(action) {
      switch(action) {
        case 'fulfill_general' : return 'Загальне поповнення';
        case 'fulfill_toTarget' : return 'Поповнення іншого складу';
        case 'fulfill_fromTarget' : return 'Поповнення зі складу';
        case 'fulfill_toTarget_confirm' : return 'Поповнення іншого складу';
        case 'fulfill_fromTarget_confirm' : return 'Поповнення зі складу';
        default : return action
      }
    },
    getRecordColor(record) {
      let color = record.type === 'add' ? 'green lighten-2' : 'red lighten-3'
      if ( record.action.includes('_confirm') ) { color = 'brown lighten-2'  }
      return color
    },
    recordNeedConfirm(record) {
      return record.action.includes('_confirm');
    },
    getTargetUserText(record) {
      if ( record.target_user ) {
        return record.target_user.firstname + " " + record.target_user.lastname
      }
    }
  },
  mounted() {
    this.loading = true
    this.GET_WAREHOUSE(this.uuid).then( (out)=>{
      if ( out.result ) {
        out.data.records = out.data.records.reverse()

        this.warehouse = out.data
        this.managersAll = out.data.managersAll

        this.records = out.data.records
        this.yearsDistinct = [...new Set(out.data.records.map(item => item.date.y))];
      }
      this.loading = false
    }).catch(()=>{ this.loading = false })

  }
}
</script>

<style scoped>
.noBorder {
  border: none !important;
}

</style>