var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-sheet',{staticClass:"py-2 px-10",attrs:{"max-height":"800"}},[_c('div',{staticClass:"d-flex justify-space-between"},[_c('h3',{staticClass:"font-weight-light mt-6"},[_vm._v(" Cклад: "),_c('b',[_vm._v(_vm._s(_vm.name))])])]),_c('v-divider',{staticClass:"mt-6 mb-2"}),_c('div',{staticClass:"compact-form justify-space-center ",class:_vm.displayShow('sm') ? '' : 'd-flex'},[_c('v-select',{attrs:{"dense":"","filled":"","rounded":"","hide-details":"","label":"Тип","items":_vm.fulfillTypes},model:{value:(_vm.fulfillType),callback:function ($$v) {_vm.fulfillType=$$v},expression:"fulfillType"}}),_c('v-select',{attrs:{"dense":"","filled":"","rounded":"","hide-details":"","label":"Склад","items":_vm.warehouses},on:{"input":_vm.getWarehouseManagers},model:{value:(_vm.targetWarehouse),callback:function ($$v) {_vm.targetWarehouse=$$v},expression:"targetWarehouse"}}),_c('v-select',{attrs:{"items":_vm.mangers_select,"dense":"","filled":"","rounded":"","hide-details":"","label":_vm.name},model:{value:(_vm.parent_user),callback:function ($$v) {_vm.parent_user=$$v},expression:"parent_user"}}),_c('v-select',{attrs:{"items":_vm.targetWarehouseUsers,"dense":"","filled":"","rounded":"","hide-details":"","label":"Підтвердження"},model:{value:(_vm.target_user),callback:function ($$v) {_vm.target_user=$$v},expression:"target_user"}})],1),_c('v-divider',{staticClass:"mt-2"}),_c('div',{staticClass:"overflow-y-auto",staticStyle:{"height":"300px"}},[_c('v-data-table',{staticClass:"mt-6",attrs:{"dense":"","items":_vm.input,"headers":_vm.headers},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"py-2"},[_c('v-avatar',{staticClass:"mr-2 ",attrs:{"size":"32"}},[_c('v-img',{attrs:{"src":item.img,"ratio":"1"}})],1),_vm._v(" "+_vm._s(item.name)+" ")],1)]}},{key:"item.quantity",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('v-sheet',{attrs:{"width":"100"}},[_c('v-text-field',{attrs:{"type":"number","dense":"","filled":"","rounded":"","hide-details":""},model:{value:(_vm.blankItems[index].quantity),callback:function ($$v) {_vm.$set(_vm.blankItems[index], "quantity", $$v)},expression:"blankItems[index].quantity"}})],1)]}},{key:"item.currentWarehouse",fn:function(ref){
var _ = ref._;
var index = ref.index;
return [(_vm.input.length > 0)?_c('div',[_c('h4',[_vm._v(_vm._s(_vm.input[index].quantity))])]):_vm._e()]}},{key:"item.targetWarehouse",fn:function(ref){
var _ = ref._;
var index = ref.index;
return [(_vm.targetWarehouseItems.length > 0)?_c('div',[_vm._v(" "+_vm._s(_vm.targetWarehouseItems[index].quantity)+" ")]):_vm._e()]}}])})],1),_c('div',{staticClass:"d-flex justify-end"},[_c('v-btn',{staticClass:"my-6",attrs:{"rounded":"","text":""},on:{"click":function($event){return _vm.$emit('close')}}},[_vm._v("Скасувати")]),_c('v-btn',{staticClass:"my-6",attrs:{"rounded":"","outlined":""},on:{"click":_vm.fulfill}},[_vm._v("Поповнити")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }