<template>
  <div>
    <div  class="d-flex align-center my-6">
      <div class="compact-form d-flex ">
        <v-select v-model="filter.year" :items="yearsDistinct"
                  clearable rounded dense filled hide-details label="Рік" class="mr-3" />
        <v-select v-model="filter.month"
                  :items="['01' , '02' , '03' , '04' , '05' , '06' , '07' , '08' , '09' , '10' , '11' , '12']"
                  clearable rounded dense filled hide-details label="Місяць" class="mr-3"  />
        <v-select v-model="filter.day"
                  :items="['01' , '02' , '03' , '04' , '05' , '06' , '07' , '08' , '09' , '10' , '11' , '12',
                             '13' , '14' , '15' , '16' , '17' , '18' , '19' , '20' , '21' , '22' , '23' , '24',
                             '25' , '26' , '27' , '28' , '29' , '30' , '31'  ]"
                  clearablev rounded dense filled hide-details label="День"  class="mr-3"  />
      </div>
    </div>
    <div v-if="recordsFiltered.length >0">
      <v-sheet outlined rounded elevation="1" class="mb-2"
               v-for="(record,i) in recordsFiltered" :key="i">

        <v-sheet dark :color="getRecordColor(record)" class="pa-2 px-6 d-flex justify-space-between align-center" >
          <h4 class="font-weight-light">
            <v-icon  > mdi-{{ record.type === 'add' ? 'plus' : 'minus' }} </v-icon>

            {{ record.date.y }}.{{ record.date.m }}.{{ record.date.d }}
           <b> {{ getActionName(record.action) }}</b>
            <v-btn v-if="record.order"  text rounded class="noCaps">
              <h3>{{ record.order.order_number }}</h3>
            </v-btn>
            <span v-if="record.targetWarehouse" >:
              <v-btn  text rounded class="noCaps"><h3>{{ record.targetWarehouse }}</h3></v-btn>
            </span>

          </h4>

          <div>
            <div v-if="record.action === 'order_new' ">
              <span v-if="record.confirmed">Замовлення виконано</span>
              <span v-if="!record.confirmed && !record.cancelled">Замовлення в роботі  </span>
              <span v-if="!record.confirmed && record.cancelled">Замовлення скасовано  </span>

            </div>

            <div  v-if="record.parent_user">
              <div v-if="record.parent_user.id === $store.state.auth.userID && !record.cancelled && !record.userConfirmed !==false" >
                <v-btn @click="denyRecord(record)" text dark rounded small class="mr-2">Cкасувати</v-btn>
                <v-btn @click="confirmRecord(record)" outlined rounded small class="mr-2">Підтвердити</v-btn>
              </div>
              <div v-else>
               <span class="caption">
                  <v-icon v-if="record.userConfirmed" small class="ml-1" color="green lighten-1">mdi-check</v-icon>
                 {{ !record.cancelled ? 'Підтвердження від' : 'Cкасовано' }} :
                 <b> {{ getTargetUserText(record.parent_user) }} </b>
                </span>  <br>
              </div>
            </div>

            <div  v-if="record.target_user">
              <div >
                     <span class="caption">
                       <v-icon v-if="record.targetUserConfirmed" small class="ml-1" color="green lighten-1">mdi-check</v-icon>
                {{ !record.cancelled ? 'Підтвердження від' : 'Cкасовано' }} : <b> {{ getTargetUserText(record.target_user) }} </b> <br>

                     </span>
                 </div>
            </div>

            <div v-if="record.confirmed_at">
              <div v-if="record.confirmed_at" class="caption text-right">{{ record.confirmed_at }}</div>
            </div>
          </div>


        </v-sheet>
        <v-divider />

        <v-simple-table dense>
          <tbody>
          <tr class="caption" v-for="(item,j) in record.items" :key="j">
            <td class="noBorder caption " width="200"  >
              <div class="pl-4">{{ item.product}}</div>
            </td>
            <td width="100" class="noBorder caption text-left" >
              {{ record.type === 'add' ? '+' : '-' }} {{ item.quantity }}
            </td>
            <td class="noBorder caption" > Залишок: {{ item.quantityTotal }}</td>
          </tr>
          </tbody>
        </v-simple-table>



      </v-sheet>
    </div>
    <h4 v-else class="font-weight-light text-center">Не має записів за вказаний період</h4>
  </div>
</template>

<script>
import {mapActions} from "vuex";

export default {
  name: "warehouseRecords",
  props : ['records'],
  data() {
    return {
      loading : true ,
      recordId : '',
      confirmation : false,

      filter : {
        year : '',
        month : '',
        day : '',
      },
    }
  },
  computed : {

    yearsDistinct() {
      let years = []
      if ( this.records) {
        years = [...new Set(this.records.map(item => item.date.y))];
      }
      return years;
    },

    recordsFiltered(){
      let records = this.records

      if ( this.filter.year ) {
        records = records.filter(el=> el.date.y === this.filter.year )
        if ( this.filter.month ) {
          records = records.filter(el=> el.date.m === this.filter.month )
          if ( this.filter.day ) {
            records = records.filter(el=> el.date.d === this.filter.day )
          }
        }
      }

      return records
    },
  },
  methods : {
    ...mapActions('warehouse', ['EDIT_RECORD_CONFIRMATION','ADD_WAREHOUSE_MANAGER','DELETE_WAREHOUSE_MANAGER']),

    denyRecord(record) {
      this.recordId = record.id
      this.confirmation = false
      this.editConfirmation()
    },
    confirmRecord(record) {
      this.recordId = record.id
      this.confirmation = true
      this.editConfirmation()
    },
    editConfirmation() {
      let data = {
        record : this.recordId,
        confirmation : this.confirmation
      }
      this.EDIT_RECORD_CONFIRMATION(data).then((out)=>{
        if ( out.result ) {
          // this.notify('SUCCESS')
          let index = this.records.findIndex(el=>el.id === out.data.id)
          this.notify( 'Операцію підтверджено' )
          if ( index !== -1 ) {
            this.$set(this.records,index,out.data)
          }
        }
        this.loadding = false
      }) .catch(()=> {  this.loadding = false })
    },

    // technical
    getActionName(action) {
      switch(action) {
        case 'fulfill_general' : return 'Загальне поповнення';
        case 'fulfill_toTarget' : return 'Поповнення іншого складу';
        case 'fulfill_fromTarget' : return 'Поповнення зі складу';
        case 'order_new' : return 'Нове замовлення :';
        case 'fulfill_fromTarget_confirm' : return 'Поповнення зі складу';
        default : return action
      }
    },
    getRecordColor(record) {
      let color = record.type === 'add' ? 'green lighten-2' : 'red lighten-3'
      if ( !record.confirmed ) { color = 'yellow darken-2'  }
      if (  record.cancelled ) { color = 'grey lighten-1'  }
      return color
    },
    recordNeedConfirm(record) {
      return record.action.includes('_confirm');
    },
    getTargetUserText(record) {
      if ( record ) {
        return record.firstname + " " + record.lastname
      }
    }

  }
  }
</script>

<style scoped>

</style>