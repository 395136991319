<template>
  <div class="pa-4" >

    <div v-if="!showManage">

      <div v-if="!showAddWarehouse">

        <div class=" justify-space-between align-center mb-8"
             :class="displayShow('sm') ? '' : 'd-flex' ">
          <h1 style="line-height: 1.2;" class="font-weight-light "
              :class="displayShow('sm') ? 'text-h5 mb-3 text-center' : 'text-h4' " >Управління Cкладами</h1>

          <div :class="displayShow('sm') ? 'd-flex justify-center' : ''">
            <v-btn-toggle  mandatory v-model="mainTabs"   color="grey darken-2"
                           :class="displayShow('sm') ? 'mx-auto' : '' " >
              <v-btn color="grey darken-2"  text class="noCaps font-weight-light">
                <v-icon class="mr-1" small color="grey darken-2" >mdi-warehouse</v-icon>
                Склади
              </v-btn>
              <v-btn color="grey darken-2"  text class="noCaps font-weight-light">
                <v-icon class="mr-1" small color="grey darken-2" >mdi-cart</v-icon>
                Всі товари
              </v-btn>
              <v-btn v-if="moduleIsActive('googleMaps') && !showBusinessToManager " color="grey darken-2"  text class="noCaps font-weight-light">
                <v-icon class="mr-1" small color="grey darken-2" >mdi-google-maps</v-icon>GoogleMaps
              </v-btn>

            </v-btn-toggle>
          </div>

        </div>

        <div v-if="mainTabs === 0">

          <div class="d-flex justify-end">
            <v-btn v-if="!showBusinessToManager"  class="mt-3 noCaps" color="grey darken-2" text
                   @click="openNewWarehouseDialog">
              <v-icon>mdi-plus</v-icon>
              Додати склад
            </v-btn>
          </div>

          <div v-if="warehousesMain.length > 0">


            <h3 class="mb-6">Основні</h3>
            <v-data-iterator hide-default-footer :items="warehousesMain" :headers="warehousesHeaders">
              <template v-slot:default="{ items  }">
                <v-row>
                  <v-col cols="12" sm="12" md="6" lg="6" xl="6"  v-for="(item,i) in items" :key="i" >
                    <v-hover v-ripple  v-slot="{ hover }">
                      <v-sheet min-height="80"
                               style="cursor: pointer"
                               :color="hover ? 'grey lighten-4' : '' "
                               class="py-2 px-3 d-flex align-center"
                               outlined rounded
                               @click="openNewWarehouseManage(item.id)"
                      >

                        <div>
                          <div class="d-flex align-center">
                            <v-icon x-large class="text-center mr-1">mdi-warehouse</v-icon>
                            <div>
                              <h3 style="line-height: 1.2" class="text-no-wrap font-weight-light mt-1">
                                {{ shortenString(item.name,27) }}</h3>


                              <div style="line-height: 1.2" class="caption mt-1">{{shortenString( item.address.city.text,30)  }} </div>



                            </div>

                          </div>
                        </div>


                      </v-sheet>
                    </v-hover>
                  </v-col>
                </v-row>
              </template>
            </v-data-iterator>
          </div>
          <div v-if="warehousesChild.length > 0">
            <h3 class="my-6">Дочірні</h3>
            <v-data-iterator hide-default-footer :items="warehousesChild" :headers="warehousesHeaders">
              <template v-slot:default="{ items  }">
                <v-row>
                  <v-col cols="12" sm="12" md="6" lg="6" xl="6"   v-for="(item,i) in items" :key="i" >
                    <v-hover v-ripple  v-slot="{ hover }">
                      <v-sheet min-height="80"
                               style="cursor: pointer"
                               :color="hover ? 'grey lighten-4' : '' "
                               class="py-2 px-3 d-flex align-center"
                               outlined rounded
                               @click="openNewWarehouseManage(item.id)"
                      >

                        <div>
                          <div class="d-flex align-center">
                            <v-icon x-large class="text-center mr-1">mdi-package-variant-closed</v-icon>
                            <div>
                              <h3 style="line-height: 1.2" class="text-no-wrap font-weight-light mt-1">
                                {{ shortenString(item.name,27) }}</h3>


                              <div style="line-height: 1.2" class="caption mt-1">{{shortenString( item.address.city.text,30)  }} </div>



                            </div>

                          </div>
                        </div>


                      </v-sheet>
                    </v-hover>

                  </v-col>
                </v-row>
              </template>
            </v-data-iterator>
          </div>
        </div>

        <div v-if="mainTabs === 1">
          <div v-if="!showBusinessToManager">
            <h1 style="line-height: 1.2;" class="text-h4 font-weight-light mt-8">Ваші Товари</h1>
            <v-sheet  elevation="1"  rounded outlined class="py-4 px-8 mt-8"  >
              <div class="d-flex justify-space-between align-center px-3 pb-5">
                <h3>Наявні товари</h3>
              </div>

              <v-divider class="mb-3" />

              <v-simple-table dense>
                <thead>
                <tr>
                  <th>Товар</th>
                  <th>Кількість</th>
                  <th>Останнє поповнення</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(product,i) in items" :key="i">

                  <td class="py-2" width="300">
                    <v-avatar class="mr-2 " size="32"  >
                      <v-img :src="product.img" ratio="1" />
                    </v-avatar>
                    {{ product.name }}
                  </td>
                  <td width="120">{{ product.quantity }} шт</td>
                  <td>
                    <div v-if="product.lastUpdate">
                      <v-chip small dark :color="product.lastUpdate.type === 'add' ? 'green lighten-2' : 'red lighten-3'">
                        <v-icon small class="mr-1">mdi-{{ product.lastUpdate.type === 'add' ? 'plus' : 'minus'  }}</v-icon>
                        <b>{{product.lastUpdate.data  }}</b>
                      </v-chip>
                    </div>

                  </td>
                </tr>
                </tbody>
              </v-simple-table>
            </v-sheet>
          </div>
        </div>

        <div v-if="mainTabs === 2">
          <warehouseLocations :warehouses="warehouses" />
        </div>







      </div>


     <editWarehouse @new="newWarehouse"
                    @close="showAddWarehouse=false"
                    :id="selectedWarehouse"
                    v-if="showAddWarehouse" />


    </div>

    <manageWarehouse @close="showManage=false" :warehouses="warehousesSelect" :uuid="selectedWarehouse" v-else/>



  </div>
</template>

<script>
import {mapActions,mapState} from "vuex";
import editWarehouse from "@/components/pages/businessDashboard/businessWarehouse/editWarehouse";
import manageWarehouse from "@/components/pages/businessDashboard/businessWarehouse/manageWarehouse";
import warehouseLocations from "@/components/pages/businessDashboard/businessWarehouse/warehouseLocations";
export default {
  name: "BusinessDashboardWarehouse",
  components : {
    editWarehouse,
    manageWarehouse,
    warehouseLocations
  },
  data(){
    return {
      warehousesHeaders : [
        {text : 'Назва', value :'name'}
      ],
      mainTabs : 0,
      step : 0,
      loading : false,
      warehouses : [],
      items : [],
      showManage : false,
      showAddWarehouse : false,
      selectedWarehouse : '',
      warehousesSelect : [],
    }
  },
  computed : {
    ...mapState('dashboard', ['showBusinessToManager']),

    warehousesMain() {
      return this.warehouses.filter(el=>el.type === 'default')
    },
    warehousesChild() {
      return this.warehouses.filter(el=>el.type === 'child')
    }
  },
  methods : {
    ...mapActions('warehouse', ['GET_WAREHOUSES','ADD_WAREHOUSE']),

    openNewWarehouseDialog() {
      this.showAddWarehouse = true
    },
    openNewWarehouseManage(id) {
      this.showManage = true
      this.selectedWarehouse = id
    },
    openEditWarehouse(id) {
      this.showAddWarehouse = true
      this.selectedWarehouse = id
    },
    newWarehouse(warehouse) {
      this.warehouses.push(warehouse)
    },

    //technical

    shortenString(string,length) {

      if ( string.length > length ) {
        return  string.slice(0,length) + ' ...'
      }
      return string
    }


  },
  mounted() {
    this.GET_WAREHOUSES().then( (out)=>{
      if ( out.result ) {
        this.warehouses = out.data.warehouses
        this.items = out.data.items
        this.warehousesSelect = []
        this.warehouses.forEach((warehouse)=>{
          this.warehousesSelect.push(
              { text : warehouse.name, value : warehouse.id }
          )
        })
      }
      this.loading = false
    }).catch(()=>{ this.loading = false })
  }
}
</script>

<style scoped>

</style>