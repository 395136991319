<template>
  <v-sheet  width="600">
  <div class="d-flex justify-space-between align-center mb-16">
    <h1 class="text-h4 font-weight-light "> {{ headingText }}</h1>

    <div>
      <v-btn @click="$emit('close')"  rounded text large   class="ml-4 mt-1">
        {{ closeText }}
      </v-btn>
      <v-btn @click="saveChanges"  rounded outlined large   class="ml-4 mt-1">
        <v-icon  class="mr-2">mdi-check</v-icon>
        {{  saveText  }}
      </v-btn>
    </div>

  </div>

    <v-row>
      <v-col>
        <v-text-field rounded filled v-model="warehouseData.name" label="Назва"/>
      </v-col>
      <v-col>
        <v-select rounded filled v-model="warehouseData.type" label="Тип" :items="wareTypes"/>
      </v-col>
    </v-row>

    <h3 class="font-weight-medium ">Адреса складу</h3>
    <v-divider />

    <v-row class="mb-6 mt-6" >
      <v-col cols="6">
        <v-select
            @change="warehouseData.address.city = { text : '', value : null } "
            v-model="warehouseData.address.country"
            :items="['Ukraine','Lituania','Estonia']"
            rounded filled
            hide-details
            label="Країна"   />
      </v-col>
      <v-col cols="6">
        <ftCitySelect v-if="warehouseData.address.country === 'Ukraine' " :densed="true"
                      v-model="warehouseData.address.city"/>
        <v-text-field class="mb-0" hide-details v-model="warehouseData.address.city.text" v-else rounded filled    label="Місто"  />
      </v-col>
      <v-col cols="8" >
        <ftStreetSelect v-model="warehouseData.address.street"
            :cityId="warehouseData.address.city.value" v-if="warehouseData.address.country === 'Ukraine' " :dense="false" />
        <v-text-field v-else class="mt-0" hide-details v-model="warehouseData.address.street.text"  rounded filled   label="Адреса"   />
      </v-col>
      <v-col cols="4">
        <v-text-field class="mt-0" hide-details v-model="warehouseData.address.info"  rounded filled   label="Номер"   />
      </v-col>
    </v-row>

    <h3 class="font-weight-medium ">Зона Доставки</h3>
    <v-divider />

    <div v-if="warehouseData.address.country && warehouseData.address.city.text">
<!--      <div v-if="selectedBusiness.delivery_permissions.includes('COUNTRIES')">-->
<!--        <div class="d-flex justify-space-between align-center">-->
<!--          <h4>Країни </h4>-->
<!--          <v-switch v-model="warehouseData.deliveryRange.params.countries.all" label="Всі Країни" dense hide-details></v-switch>-->
<!--        </div>-->
<!--        <v-simple-table v-if="warehouseData.deliveryRange.params.countries.all">-->
<!--          <tbody>-->
<!--          <tr v-for="country in warehouseData.deliveryRange.countries.items" :key="country">-->
<!--            <td>{{ country }}</td>-->
<!--          </tr>-->
<!--          </tbody>-->
<!--        </v-simple-table>-->
<!--        <v-btn>Додати</v-btn>-->
<!--      </div>-->
<!--      <div v-if="selectedBusiness.delivery_permissions.includes('CITIES')">-->
<!--        <div class="d-flex justify-space-between align-center">-->
<!--          <h4>Міста </h4>-->
<!--          <v-switch label="Всі Міста Країн"  dense hide-details></v-switch>-->
<!--        </div>-->
<!--      </div>-->

      <div class="d-flex justify-space-between align-center">
        <h4>Вулиці </h4>
        <v-switch
            v-model="warehouseData.deliveryRange.params.streets.all" label="Всі Вулиці Міст"  dense hide-details></v-switch>
      </div>
      <div v-if="!warehouseData.deliveryRange.params.streets.all">
        <v-row class="mt-2">
          <v-col cols="9">

            <ftStreetSelect v-model="newStreet"
                :cityId="warehouseData.address.city.value"
                v-if="warehouseData.address.country === 'Ukraine' " :dense="false" />
          </v-col>
          <v-col cols="3">
            <v-btn @click="addStreet"
                x-large elevation="0" color="grey lighten-2" dark rounded filled>Додати</v-btn>
          </v-col>
        </v-row>
        <v-simple-table dense class="mb-16 mt-6">
          <thead>
          <tr>
            <th>Вулиці</th>
          </tr>
          </thead>
          <tbody>

          <tr v-for="(street,i) in warehouseData.deliveryRange.items" :key="i">
            <td>{{ street.streetName }}</td>
          </tr>
          </tbody>
        </v-simple-table>

        <div class="mb-16 pa-16"></div>

      </div>

    </div>

    <span v-else class="caption"><br> Введіть країну та місто складу</span>

    <div class="d-flex justify-end mt-6">
      <div>
        <v-btn @click="$emit('close')"  rounded text large   class="ml-4 mt-1">
          Скасувати
        </v-btn>
        <v-btn @click="saveChanges"  rounded outlined large   class="ml-4 mt-1">
          <v-icon  class="mr-2">mdi-check</v-icon>
          Зберегти
        </v-btn>
      </div>

    </div>


  </v-sheet>
</template>

<script>
import {mapActions,mapState} from "vuex";
import ftCitySelect from "@/components/UI/ftCitySelect";
import ftStreetSelect from "@/components/UI/ftStreetSelect";

export default {
  name: "editWarehouse",
  props : ['id'],
  components : {
    ftCitySelect,
    ftStreetSelect
  },
  data() {
    return {
      warehouseData : {
        name : '',
        address : {
          city : {},
          street : {}
        },
        type : 'default',
        deliveryRange : {
          params : {
            countries : {
              active : false,
              all : true,
            },
            cities : {
              active : false,
              all : true,
            },
            streets : {
              active : true,
              all : true,
            },
          },
          items : []
        },
      },
      wareTypes : [
        { text : 'Звичайний', value : 'default' },
        { text : 'Дочірній' , value : 'child' }
      ],
      newStreet : {},
    }
  },
  computed : {
    ...mapState('business', ['selectedBusiness']),
    headingText() {
      return this.id ? 'Налаштування' : 'Додати склад'
    },
    saveText() {
      return this.id ? 'Зберети' : 'Додати'
    },
    closeText() {
      return this.id ? 'Назад' : 'Скасувати'
    }
  },
  methods : {
    ...mapActions('warehouse', ['ADD_WAREHOUSE','GET_WAREHOUSE']),
    createWarehouse() {
      this.loading = true
      this.ADD_WAREHOUSE(this.warehouseData).then((out)=>{
        if ( out.result ) {
          this.notify('Новий склад створено')
          this.$emit('new',out.data)
          this.$emit('close')
        }
        this.loading = false
      }).catch(()=>{  this.loading = false  })
    },
    getWarehouse(){
      this.loading = true
      this.GET_WAREHOUSE(this.id).then( (out)=>{
        if ( out.result ) {
          this.warehouseData = out.data
          this.warehouseData.name = out.data.name
          this.warehouseData.deliveryRange.params = out.data.params
          console.log(out.data.params)
        }
        this.loading = false
      }).catch(()=>{ this.loading = false })
    },
    addStreet() {
      if ( this.newStreet.value ) {
        let check = this.warehouseData.deliveryRange.items.find(el=>el.value === this.newStreet.value)
        if ( check) { return this.notify('Вулиця вже існує') }
        let country = this.selectedBusiness.delivery_permissions.includes('COUNTRIES') ? 'country' : this.warehouseData.address.country
        let city = this.selectedBusiness.delivery_permissions.includes('CITIES') ? '' : this.warehouseData.address.city.value

        this.warehouseData.deliveryRange.items.push(
            {
              streetName : this.newStreet.text,
              street : this.newStreet.value,
              city : city,
              country : country,
            })

        this.newStreet = {}
      }
    },
    saveChanges() {
      if (!this.id)  { this.createWarehouse() }
    }
  },
  mounted() {

    if (this.id ) { this.getWarehouse() }
  }
}
</script>

<style scoped>

</style>