<template>
<div>
  <v-menu offset-y v-model="show">
    <template v-slot:activator="{ on, attrs }">
      <v-text-field @input="queryCity()" filled rounded
                    v-model="text" label="Вулиця"
                    v-bind="attrs"
                    v-on="on"
                    clearable
                    :hide-details="densed"

      >
        <template slot="append">
          <v-btn @click="show=!show" icon class="mt-n1"><v-icon>mdi-magnify</v-icon></v-btn>
        </template>
      </v-text-field>
    </template>
    <v-sheet max-height="220" style="overflow-y: auto">
      <v-list dense>
        <v-subheader v-if="citySelect.length===0" class="pl-5">Місто не знайдено</v-subheader>
        <v-list-item-group>
          <v-list-item v-for="(location, index) in citySelect" :key="index"
                        @click="setCity(location)">
            <v-list-item-title>{{ location.text }}</v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-sheet>

  </v-menu>
</div>

</template>

<script>
import {mapActions} from "vuex";

export default {
  name: "ftStreetSelect",
  props : ['value','densed','cityId'],
  data(){
    return {

      show : false,

      loading : false,

      text       : '',
      city       : '',
      citySelect : [],
    }
  },
  watch : {
    text() {
      this.$emit('input',this.value)
    },
    value() {
      this.text = this.value.text
    }
  },
  methods : {
    ...mapActions('location', [ 'QUERY_STREET' ]),

    queryCity() {
      this.loading = true
      if (this.text.length >2) {
        this.QUERY_STREET({city: this.cityId, street: this.text})
            .then((out)=>{
          if(out.data) {
            this.citySelect = out.data
          }
          this.loading = false
              this.show = true
        })
            .catch(()=>{ this.loading = false })
      }
    },
    setCity(location) {
       this.text = location.text
       this.$emit('input',location)
       this.$emit('city',location.value)
    }
  },
  mounted() {
    if ( this.value ) {
      this.text = this.value.text
    }
  }
}
</script>

<style scoped>

</style>