<template>
  <div >
    <warehouseGoogleMaps :warehouses="warehouses" />
  </div>

</template>

<script>
import warehouseGoogleMaps from "@/components/pages/businessDashboard/businessWarehouse/warehouseGoogleMaps";
export default {
  name: "warehouseLocations",
  props : ['warehouses'],
  components : {
    warehouseGoogleMaps
  },
  data() {
    return {
      limited : false,
    }
  },
  methods : {
    getLimitedText(value) {
      return value ? 'Обмежено' : 'Не обменжено'
    }
  }

}
</script>

<style scoped>

</style>