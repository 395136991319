<template>
  <div>

    <div class="d-flex justify-space-between align-center mb-6">
      <v-sheet width="250" outlined class="pa-3">
        <gmap-autocomplete style="width: 220px" @place_changed="updateCenter($event)" />
      </v-sheet>

      <v-btn dark rounded color="green lighten-2" @click="saveChanges">Зберегти</v-btn>

    </div>


    <div style="position: relative">
      <gmap-map  :center="mapCenter"
                  :options="{
                      disableDefaultUI : true,
                      zoomControl : true,
                  }"
                 :zoom="12"
                 style="width: 100%; height: 500px" ref="map">

        <gmap-polygon @click="selectedWarehouse = area"
                      v-for="(area,i) in warehousesAreas" :key="i"
                      :paths="area.paths"
                      :editable="true"
                      @paths_changed="updateEdited($event,i)"
                      :options="{
                      draggable:true,
                      strokeWeight: 1.0,
                      strokeColor: 'orange',
                      fillColor : area.color, }"
                      @rightclick="deletePoint($event,i)"



                      :ref="'polygon_' + i">
        </gmap-polygon>
        <GmapMarker :position="marker"  />

      </gmap-map>

      <v-fade-transition>
        <v-menu v-if="warehousesSelect.length>0"  offset-y transition="slide-y-transition">

          <template
              v-slot:activator="{ on, attrs }">
            <v-btn  @click="selectedWarehouse = null"
                    v-bind="attrs" v-on="on"
                    class="noCaps"
                    elevation="1"

                    style="position: absolute; top : 12px; left: 15px"  >
              <v-icon color="grey darken-2">mdi-plus</v-icon>Додати склад</v-btn>
          </template>

          <v-sheet width="300"  class="pa-6">


            <div @click="addWarehouseRegion(item)"
                 class="headerMenuItem" v-ripple v-for="(item,i) in warehousesSelect " :key="i">{{ item.text }}</div>


          </v-sheet>

        </v-menu>
      </v-fade-transition>
      <v-fade-transition>
        <v-sheet elevation="1" v-if="selectedWarehouse.color"
                 width="250"
                 class="py-1 px-4"
                 style="
               position: absolute;
               top:12px;
               left: 50%;
               transform: translate(-50%, 0);
            ">
          <div >
            <div class="d-flex justify-space-between align-center">
              <span class="caption">{{ selectedWarehouse.warehouseName }}</span>
              <v-icon :color="selectedWarehouse.color">mdi-square</v-icon>
            </div>
          </div>

        </v-sheet>
      </v-fade-transition>

      <v-fade-transition>
        <v-sheet v-if="warehousesAreas.length > 0"
                 width="250"
                 class="py-1 px-4"
                 style="
               position: absolute;
               top:12px;
               right: 15px;
            ">
          <div >
            <div v-for="(area,i) in warehousesAreas" :key="i"
                 class="d-flex justify-space-between align-center">
              <span class="caption">{{ area.warehouseName }}</span>
              <v-icon :color="area.color">mdi-square</v-icon>
            </div>
          </div>

        </v-sheet>
      </v-fade-transition>

    </div>




  </div>
</template>

<script>
import {mapActions} from "vuex";

export default {
  name: "warehouseGoogleMaps",
  props : ['warehouses'],
  data() {
    return {
      selectedWarehouseId : null,
      selectedWarehouse: {},
      mapCenter : { lat: 46.975033, lng: 31.994583 },
      warehousesAreas : [],
      paths: [],
      marker : { lat: 46.975033, lng: 31.994583 },
      markerColor : 'green',
    }
  },
  computed : {
    warehousesSelect() {
      let items = []

      let checkArray = []
      this.warehousesAreas.forEach((area)=>{
        checkArray.push(area.warehouseId)
      })

      if ( this.warehouses ) {
        this.warehouses.forEach((warehouse)=>{
          if ( !checkArray.includes(warehouse.id) || checkArray.length === 0 ) {
            items.push({ text:warehouse.name, value : warehouse.id })
          }
        })
      }

      return items
    }
  },
  methods : {
    ...mapActions('warehouse', [ 'CHECK_LOCATION_WAREHOUSE', 'SAVE_GOOGLE_LOCATIONS','GET_GOOGLE_LOCATIONS']),
    saveChanges(){
      if ( this.warehousesAreas.length === 0 ) {
        return this.notify('Жодного складу не обрано')
      }
      this.loading = true

      this.SAVE_GOOGLE_LOCATIONS( this.warehousesAreas ).then( (out)=>{

        if ( out.result ) {
          this.notify('Зміни збережено')
        }
        this.loading = false
      }).catch(()=>{ this.loading = false})
    },
    updateCenter(place) {
      console.log(place)

      let location = {
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng(),
      }

      this.CHECK_LOCATION_WAREHOUSE(location).then((out)=>{
        if (out.result) {
          if ( out.data.warehouse ) {
            this.notify(out.data.warehouse)
          } else {
            this.notify('Дана адреса не відноситься до жодного складу')
          }

        }
      })

      this.mapCenter = location
      this.marker = location
      console.log(location)
    },
    addWarehouseRegion(item) {
      this.selectedWarehouseId = item.id
      let newWarehouseArea = {
        warehouseId : item.value,
        warehouseName : item.text,
        color : this.randomColor(),
        paths : this.getNewPath()
      }
      this.warehousesAreas.push(newWarehouseArea)
      this.selectedWarehouse = Object.assign({},newWarehouseArea)

    },
    getNewPath() {
      // obtain the bounds, so we can guess how big the polygon should be
      var bounds = this.$refs.map.$mapObject.getBounds()

      var northEast = bounds.getNorthEast()
      var southWest = bounds.getSouthWest()
      var center = bounds.getCenter()
      var degree = this.paths.length + 1;
      var f = Math.pow(0.66, degree)

      // Draw a triangle. Use f to control the size of the triangle.
      // i.e., every time we add a path, we reduce the size of the triangle
      var path = [
        {lng: center.lng(), lat: (1 - f) * center.lat() + (f) * northEast.lat()},
        {lng: (1 - f) * center.lng() + (f) * southWest.lng(), lat: (1 - f) * center.lat() + (f) * southWest.lat()},
        {lng: (1 - f) * center.lng() + (f) * northEast.lng(), lat: (1 - f) * center.lat() + (f) * southWest.lat()},
      ]

      return path
    },
    updateEdited(mvcArray,index) {

      let newPath = [];

      for ( let i = 0 ; i < mvcArray.getAt(0).length ; i++  ) {
        newPath.push({
          lat : mvcArray.getAt(0).getAt(i).lat(),
          lng : mvcArray.getAt(0).getAt(i).lng()
        })

      }
      let newWarehouseArea = this.warehousesAreas[index];
      newWarehouseArea.paths = newPath

      this.$set(this.warehousesAreas,index,newWarehouseArea)


    },
    deletePoint($event,index) {

      if ($event.vertex) {
        if ( this.$refs['polygon_' + index][0] ) {
          if ( this.warehousesAreas[index].paths.length <4 ) {
            return this.notify('Видаліть обєект вручну')}
          this.$refs['polygon_' + index][0].$polygonObject.getPaths()
              .getAt($event.path)
              .removeAt($event.vertex)
        } else {
          console.log('Polygon Object not Found by Ref')
        }

      }
    },

    //technical
    randomColor() {
      let colors = ['green','blue','red','orange','teal','pink'];
      if ( this.warehousesAreas.length < 7) {
        return colors[this.warehousesAreas.length]
      } else {
        return 'orange'
      }
    },

  },
  mounted() {
    this.loading = true
    this.GET_GOOGLE_LOCATIONS().then((out)=>{
      if ( out.result ) {
        if ( out.data) {
          this.warehousesAreas = out.data
        }

      }
      this.loading = false
    }).catch(()=> { this.loading=false })
  }
}
</script>

<style scoped>
.headerMenuItem {
  cursor: pointer;
  font-size: 1rem !important;
  transition: all 0.2s ease;
  text-decoration: none;
}
.headerMenuItem:hover {
  background-color: #dbdbdb;
}
</style>