var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"d-flex justify-space-between align-center mb-6"},[_c('v-sheet',{staticClass:"pa-3",attrs:{"width":"250","outlined":""}},[_c('gmap-autocomplete',{staticStyle:{"width":"220px"},on:{"place_changed":function($event){return _vm.updateCenter($event)}}})],1),_c('v-btn',{attrs:{"dark":"","rounded":"","color":"green lighten-2"},on:{"click":_vm.saveChanges}},[_vm._v("Зберегти")])],1),_c('div',{staticStyle:{"position":"relative"}},[_c('gmap-map',{ref:"map",staticStyle:{"width":"100%","height":"500px"},attrs:{"center":_vm.mapCenter,"options":{
                    disableDefaultUI : true,
                    zoomControl : true,
                },"zoom":12}},[_vm._l((_vm.warehousesAreas),function(area,i){return _c('gmap-polygon',{key:i,ref:'polygon_' + i,refInFor:true,attrs:{"paths":area.paths,"editable":true,"options":{
                    draggable:true,
                    strokeWeight: 1.0,
                    strokeColor: 'orange',
                    fillColor : area.color, }},on:{"click":function($event){_vm.selectedWarehouse = area},"paths_changed":function($event){return _vm.updateEdited($event,i)},"rightclick":function($event){return _vm.deletePoint($event,i)}}})}),_c('GmapMarker',{attrs:{"position":_vm.marker}})],2),_c('v-fade-transition',[(_vm.warehousesSelect.length>0)?_c('v-menu',{attrs:{"offset-y":"","transition":"slide-y-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"noCaps",staticStyle:{"position":"absolute","top":"12px","left":"15px"},attrs:{"elevation":"1"},on:{"click":function($event){_vm.selectedWarehouse = null}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"grey darken-2"}},[_vm._v("mdi-plus")]),_vm._v("Додати склад")],1)]}}],null,false,2354111128)},[_c('v-sheet',{staticClass:"pa-6",attrs:{"width":"300"}},_vm._l((_vm.warehousesSelect),function(item,i){return _c('div',{directives:[{name:"ripple",rawName:"v-ripple"}],key:i,staticClass:"headerMenuItem",on:{"click":function($event){return _vm.addWarehouseRegion(item)}}},[_vm._v(_vm._s(item.text))])}),0)],1):_vm._e()],1),_c('v-fade-transition',[(_vm.selectedWarehouse.color)?_c('v-sheet',{staticClass:"py-1 px-4",staticStyle:{"position":"absolute","top":"12px","left":"50%","transform":"translate(-50%, 0)"},attrs:{"elevation":"1","width":"250"}},[_c('div',[_c('div',{staticClass:"d-flex justify-space-between align-center"},[_c('span',{staticClass:"caption"},[_vm._v(_vm._s(_vm.selectedWarehouse.warehouseName))]),_c('v-icon',{attrs:{"color":_vm.selectedWarehouse.color}},[_vm._v("mdi-square")])],1)])]):_vm._e()],1),_c('v-fade-transition',[(_vm.warehousesAreas.length > 0)?_c('v-sheet',{staticClass:"py-1 px-4",staticStyle:{"position":"absolute","top":"12px","right":"15px"},attrs:{"width":"250"}},[_c('div',_vm._l((_vm.warehousesAreas),function(area,i){return _c('div',{key:i,staticClass:"d-flex justify-space-between align-center"},[_c('span',{staticClass:"caption"},[_vm._v(_vm._s(area.warehouseName))]),_c('v-icon',{attrs:{"color":area.color}},[_vm._v("mdi-square")])],1)}),0)]):_vm._e()],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }