<template>
  <v-sheet max-height="800" class="py-2 px-10" >

    <div class="d-flex justify-space-between">
      <h3 class="font-weight-light mt-6"> Cклад:   <b>{{ name }}</b>  </h3>
    </div>
    <v-divider class="mt-6 mb-2" />
    <div  class="compact-form justify-space-center "
         :class="displayShow('sm') ? '' : 'd-flex'" >
      <v-select  dense filled rounded hide-details label="Тип" v-model="fulfillType" :items="fulfillTypes"></v-select>
      <v-select  @input="getWarehouseManagers" dense filled rounded hide-details label="Склад" v-model="targetWarehouse" :items="warehouses" />
      <v-select v-model="parent_user" :items="mangers_select" dense filled rounded hide-details :label="name" />
      <v-select  v-model="target_user" :items="targetWarehouseUsers" dense filled rounded hide-details label="Підтвердження" />
    </div>


    <v-divider class="mt-2" />


    <div style="height: 300px " class="overflow-y-auto">
      <v-data-table class="mt-6" dense :items="input" :headers="headers">

          <template v-slot:item.name="{item}">
            <div class="py-2" >
              <v-avatar class="mr-2 " size="32"  >
                <v-img :src="item.img" ratio="1" />
              </v-avatar>
              {{ item.name }}
            </div>
          </template>

          <template v-slot:item.quantity="{ item,index }">
            <v-sheet width="100">
              <v-text-field type="number"  dense filled rounded hide-details v-model="blankItems[index].quantity" />
            </v-sheet>
          </template>

          <template v-slot:item.currentWarehouse="{ _,index }">
            <div v-if="input.length > 0" >
              <h4>{{ input[index].quantity }}</h4>
            </div>
          </template>

          <template v-slot:item.targetWarehouse="{ _,index }">
            <div v-if="targetWarehouseItems.length > 0" >
              {{ targetWarehouseItems[index].quantity }}
            </div>
          </template>



        </v-data-table>
    </div>

      <div class="d-flex justify-end">
        <v-btn rounded text class="my-6" @click="$emit('close')">Скасувати</v-btn>
        <v-btn rounded outlined class="my-6" @click="fulfill">Поповнити</v-btn>
      </div>


  </v-sheet>
</template>

<script>
import {mapActions} from "vuex";

export default {
  name: "fulfilWarehouse",
  props : ['input','uuid','warehouses','name','type','managers'],
  data() {
    return {
      fulfillType : 'general',
      targetWarehouse : '',
      targetWarehouseItems : [],
      blankItems : [],
      target_user : '',
      parent_user : '',
      targetWarehouseUsers : []

    }
  },
  watch : {
    targetWarehouse() {
      if ( this.targetWarehouse ) {
        this.getWarehouse(this.targetWarehouse)
      }
    }
  },
  computed : {
    fulfillTypes(){
      let types = [
          { text : 'Поповнити інший склад' , value : 'toTarget'},
          { text : 'Із складу' , value : 'fromTarget'},
      ]

      if ( this.type === 'default') {
        types.unshift({ text : 'Загальний' , value : 'general'} )
      }
      return types
    } ,
    headers() {

      let headers = [ ]

      if ( this.fulfillType === 'general' ) {
        headers =  [
          { text: "Товар", value: 'name'},
          { text: "Кількість", value: 'quantity'},
        ]
      } else {
        headers =  [
          { text: "Товар"           ,  value: 'name'},
          { text: "Поточний Склад",  value: 'currentWarehouse'},
          { text: "Кількість"       ,  value: 'quantity'},
          { text: "Інший склад" ,  value: 'targetWarehouse'},
        ]
      }


      return headers
    },
    mangers_select() {
      let items = [];
      if ( this.managers) {
        this.managers.forEach((manager)=>{
          items.push({ text: manager.firstname + ' ' + manager.lastname , value : manager.id })
        })
      }

      return items;
    }
  },
  methods : {
    ...mapActions('warehouse', ['FULFILL_WAREHOUSE','GET_WAREHOUSE','GET_WAREHOUSE_MANAGERS']),

    validateFulfil() {
      let items = this.blankItems.filter(el => el.quantity > 0 );
      if ( items.length <= 0 ) {
        this.notify( 'Жодного товару не вибрано' )
        return false
      }

      return true
    },
    fulfill(){
      if ( !this.validateFulfil() ) { return }
      this.loading = true

      let items = this.blankItems.filter(el => el.quantity > 0 );
        this.FULFILL_WAREHOUSE(
            {
              id : this.uuid,
              data : {
                items : items,
                target: this.targetWarehouse,
                target_user : this.target_user,
                parent_user : this.parent_user,
                type : this.fulfillType,
              }

            }
          ).then( (out)=>{

          if ( out.result ) {
            this.notify('Cклад поповнено')
            this.$emit('update',out.data)
          } else {
            this.notify(out.error)
          }
          this.loading = false
        }).catch((error)=>{
          this.notify(error)
          this.loading = false
        })


    },



    getWarehouse(id) {
      this.loading = true
      this.GET_WAREHOUSE(id).then( (out)=>{
        if ( out.result ) {
          this.targetWarehouseItems = out.data.items
        }
        this.loading = false
      }).catch(()=>{ this.loading = false })
    },
    getWarehouseManagers(warehouseId){

      this.loading = true
      this.GET_WAREHOUSE_MANAGERS(warehouseId).then((out)=>{
        if ( out.result ) {
          this.targetWarehouseUsers = out.data
        }
        this.loading = false
      }).catch(()=>{this.loading = false})

      console.log(warehouseId)
    }
  },
  mounted() {
    if (this.input.length > 0) {
      this.input.forEach((product)=>{
        this.blankItems.push({
          name : product.name,
          id : product.id,
          quantity : 0,
        })
      })
    }
  }
}
</script>

<style scoped>

</style>