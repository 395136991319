<template>
  <div >
    <div class="d-flex justify-space-between align-center">
      <h2 class="mb-6 mt-8">Менеджери: </h2>
      <div class="d-flex">

        <v-select v-model="newManager" :items="managersSelect" label="Менеджер"></v-select>
        <v-btn @click="addManager" dark rounded class="noCaps mt-5 ml-3" color="green lighten-2">
          <v-icon>mdi-plus</v-icon>
          Додати
        </v-btn>
      </div>
    </div>
    <v-simple-table>
      <tbody>
      <tr v-for="(user,i) in users " :key="i">
        <td>
          <h4>{{ user.firstname }} {{ user.lastname }}</h4>
          <span>{{ user.email }}</span>

        </td>
        <td>
          Permission: <br>
          <v-chip>Confirm</v-chip>
        </td>
        <td>
          <v-btn class="noCaps" text color="red lighten-2" @click="deleteManager(user.id)">
            <v-icon>mdi-delete</v-icon>
            Видалити
          </v-btn>
        </td>
      </tr>
      </tbody>
    </v-simple-table>
  </div>
</template>

<script>
import {mapActions} from "vuex";

export default {
  name: "warehouseManagers",
  props : ['users','managersAll','id'],
  data(){
    return {
      newManager : '',
    }
  },
  computed : {
    managersSelect() {
      let items = [];

      let existingManagers = [];
      this.users.forEach((user)=>{
        existingManagers.push(user.id)
      })

      this.managersAll.forEach((manager)=>{
        if ( !existingManagers.includes(manager.id) ){
          items.push({
            text : manager.firstname + ' ' + manager.lastname,
            value : manager.id
          })
        }

      })
      return items
    }
  },
  methods : {
    ...mapActions('warehouse', ['ADD_WAREHOUSE_MANAGER','DELETE_WAREHOUSE_MANAGER']),

    addManager() {
      if ( !this.newManager ) { return this.notify('Оберіть менеджера') }
      this.loading = true

      this.ADD_WAREHOUSE_MANAGER({
        user : this.newManager,
        warehouse: this.id
      }).then((out)=>{

        if ( out.result ) {
          this.users.push(out.data)
        }
        this.loading = false
      }).then(()=>{ this.loading = false })
    },
    deleteManager(id) {

      this.loading = true

      this.DELETE_WAREHOUSE_MANAGER({
        user : id,
        warehouse: this.id
      }).then((out)=>{

        if ( out.result ) {
          let index = this.users.findIndex(el =>el.id === id)
          if ( id ) {
            this.users.splice(index,1)
          }
        }
        this.loading = false
      }).then(()=>{ this.loading = false })
    },
  }
}
</script>

<style scoped>

</style>